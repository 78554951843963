import { ActionFunctionArgs, data, Link } from "react-router";
import { useFetch } from "~/utils/useFetch";
import { MixPanel } from "~/utils/MixPanel";

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const formData = await request.formData();
  switch (formData.get("_action")) {
    case "add": {
      const waitingListType = formData.get("waitingListType");
      const body = {
        waitingListType,
      };
      await useFetch({
        request,
        context,
        url: `/User/AddToWaitingList`,
        method: "POST",
        body,
      });
      return data({ added: true });
    }
  }
  return data({ added: false });
};
export default function DefaultRoute() {
  return (
    <>
      <div className="flex h-screen">
        <div className="card max-w-5xl m-auto ">
          <div className="card-body">
            <div className="hero  ">
              <div className="hero-content flex-col lg:flex-row-reverse">
                <img
                  src={"/404.webp"}
                  className="max-w-[18rem] rounded-3xl shadow-2xl xl:mx-16 my-4"
                />
                <div className="max-w-md">
                  <h1 className="text-md xl:text-3xl text-primary font-bold">
                    You're offside!
                  </h1>
                  <h1 className="text-3xl xl:text-3xl lg:text-5xl font-bold"></h1>
                  <p className="py-6">
                    {" "}
                    Something went wrong. Click on the button below to navigate
                    back to the homepage.
                  </p>

                  <Link
                    to={`/`}
                    className={`btn btn-primary`}
                    onClick={() => {
                      MixPanel.track("mft_button_404_back_to_homepage");
                    }}
                  >
                    Go back to the homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
